<template>
    <div class="row">
        <div class="col-md-12">
            <div class="row-spacing">    
                <button class="btn btn-info" disabled @click="openDirectivesModal()">Edit PHP Directives</button>
                <button class="btn btn-warning" @click="deleteDirectives(website.websitePhp)" :disabled="!website.websitePhp.phpDirectives">Delete PHP directives</button>
            </div> 
            <div class="row-spacing">
                <div class="col-md-12">   
                    <textarea class="form-control" disabled
                        v-model="website.websitePhp.phpDirectives" cols="15" rows="15">
                    </textarea>
                    <div class="input-group mt-2">
                            <button disabled @click.stop="copyText(website.websitePhp.phpDirectives)" type="button" class="btn btn-info"  v-tooltip="'Copy to clipboard'">
                                <i class="fa fa-clipboard"></i>
                            </button>
                    </div>
                </div>
            </div>
            <AddWebHostingPhpDirectivesModal
                ref="addDirectivesModal"
                :phpDirectives="website.websitePhp.phpDirectives"
                :newWebsitePhp="newWebsitePhp"
                @saveDirectives="saveDirectives"
            /> 
        </div>
    </div>
</template>

<script>
import AddWebHostingPhpDirectivesModal from '@/components/Admin/Modals/AddWebHostingPhpDirectivesModal';

export default {
    components: {
        AddWebHostingPhpDirectivesModal
    },
    props: {
        website: {
            type: Object,
            required: true,
        },
        newWebsitePhp: {
            type: Object,
            required: true
        }
    },
    methods: {
        saveDirectives: function(data) {
            const hostUrl = this.$config.aimsAPI;
            const saleNumber = this.$route.params.saleNumber;
            this.$refs.addDirectivesModal.$refs.addDirectivesModal.isLoading();
            if (this.website.websitePhp.websitePhpNumber && this.website.websitePhp.websitePhpNumber != 0) {
                this.website.websitePhp.phpDirectives = data.phpDirectives;
                this.$http.put(`${hostUrl}sales/definition/${saleNumber}/obj/update`, this.website.websitePhp).then(
                    (response) => {
                        this.showSuccess("Successfully updated PHP Directives");
                        this.$emit("loadSaleDefinition");
                    },
                    (error) => {
                        this.showError("Error updating PHP Directives", error);
                        console.error(error);
                    }
                ).finally(() => {
                    this.$emit("loadSaleDefinition");
                    this.$refs.addDirectivesModal.$refs.addDirectivesModal.isLoading();
                    this.$refs.addDirectivesModal.$refs.addDirectivesModal.close();
                });   
            } else {
                data.websiteNumber = this.website.websiteNumber;
                this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/create`, data).then(
                    (response) => {
                        this.showSuccess("Successfully created PHP Directives");
                        this.$emit("loadSaleDefinition");
                    },
                    (error) => {
                        this.showError("Error adding PHP Directives", error);
                        console.error(error);
                    }
                ).finally(() => {
                    this.$emit("loadSaleDefinition");
                    this.$refs.addDirectivesModal.$refs.addDirectivesModal.isLoading();
                    this.$refs.addDirectivesModal.$refs.addDirectivesModal.close();
                });  
            }
        },
        openDirectivesModal: function() {
            this.$refs.addDirectivesModal.open();            
        },
        deleteDirectives: function(data) {
            this.$swal({
                title: "Are you sure you want to delete PHP Directives?",
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    const hostUrl = this.$config.aimsAPI;
                    const saleNumber = this.$route.params.saleNumber;
                    this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/delete/`, data).then(
                        (response) => {
                            this.showSuccess(response.data.message);
                            this.$emit("loadSaleDefinition");
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error deleting PHP Directives", error);
                        }
                    );
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>

</style>